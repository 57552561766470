export const environment = {
  production: true,
};

export function getEnvironmentName() {
  return 'Test';
}

export function getRootUrl() {
  return 'https://api-test.nzkungfuschool.com';
}
