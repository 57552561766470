import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.maybeLogout();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  maybeLogout() {
    const lastActivity = localStorage.getItem('last-activity');
    if (!lastActivity) {
      this.authenticationService.logout();
      return;
    }
    try {
      const now = new Date().getTime() / 1000;
      const delay = now - Number(lastActivity);
      if (delay > 3 * 60 * 60) {
        this.authenticationService.logout();
      }
    } catch (e) {
      console.error('failed to maybeLogOut()', e);
    }
  }
}
