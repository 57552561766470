export const WHITE_BELT = 1;

export const STUDENT_ROLE = 1;
export const ASSISTANT_ROLE = 20;
export const INSTRUCTOR_ROLE = 30;
export const ADMIN_ROLE = 100;

export const DEFAULT_SCHOOL_ID = 1;
export const DEFAULT_INSTRUCTOR_ID = 1;

export const STUDENT_ID_KEY = 'studentId';
export const SCHOOL_ID_KEY = 'schoolId';

export const NO_SERVER = 'Sorry, the server is unavailable. Please try again later.';
