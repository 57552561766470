import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { AuthenticationService } from '../services/authentication.service';
import { StudentDetails } from '../model/student-details';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  private student: StudentDetails;

  constructor(
    private router: Router,
    private studentService: StudentService,
    private authenticationService: AuthenticationService,
    private menu: MenuController
  ) {}

  ngOnInit() {}

  isStudent() {
    return this.student != null;
  }

  gotoDetails() {
    const currentUser = this.authenticationService.getCurrentUser();
    if (currentUser) {
      this.studentService.getStudentDetailsByLogin(currentUser).subscribe((student) => {
        this.student = student;
        this.router.navigate(['students', 'student', this.student.id]);
        this.menu.close();
        const thisPage = ['home'].toString();
        console.log('I am storing this page for later', thisPage);
        localStorage.setItem('lastPage', thisPage);
      });
    }
  }

  gotoStudents() {
    this.router.navigate(['students']);
    this.menu.close();
  }

  gotoClasses() {
    this.router.navigate(['classes']);
    this.menu.close();
  }

  gotoGradings() {
    this.router.navigate(['gradings']);
    this.menu.close();
  }

  gotoSchools() {
    this.router.navigate(['schools']);
    this.menu.close();
  }

  gotoSystem() {
    this.router.navigate(['system']);
    this.menu.close();
  }

  gotoLogin() {
    this.router.navigate(['login']);
    this.menu.close();
  }

  gotoRegistrations() {
    this.router.navigate(['administration', 'registrations']);
    this.menu.close();
  }

  gotoVersionHistory() {
    this.router.navigate(['version-history']);
    this.menu.close();
  }

  isAdmin() {
    return this.authenticationService.isAdmin();
  }

  isInstructor() {
    return this.authenticationService.isInstructor();
  }

  isAdminOrInstructor() {
    return this.authenticationService.isAdminOrInstructor();
  }

  isAdminOrInstructorOrAssistant() {
    return this.authenticationService.isAdminOrInstructorOrAssistant();
  }

  logout() {
    this.authenticationService.logout();
    this.menu.close();
    this.router.navigate(['login']);
  }

  hide() {
    this.menu.close();
  }

  home() {
    this.router.navigate(['home']);
    this.menu.close();
  }

  isLoggedIn() {
    return this.authenticationService.isAuthenticated();
  }
}
