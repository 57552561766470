import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationResponse } from '../model/authentication-response';
import { getRootUrl } from 'src/environments/environment';
import { Outcome } from '../model/outcome';
import { ToastService } from './toast.service';

const TOKEN_KEY = 'token';
const LOGIN = 'login';
const ROLE = 'role';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public loginSuccess: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient, private toastService: ToastService) {}

  login(login: string, password: string) {
    this.getToken(login, password).subscribe(
      (data) => {
        const decodedToken = this.decodeToken(data.accessToken);
        if (decodedToken && this.isTokenStillValid(decodedToken)) {
          localStorage.setItem(TOKEN_KEY, data.accessToken);
          localStorage.setItem(LOGIN, login);
          localStorage.setItem(ROLE, ('' + decodedToken.role).toLowerCase());
          this.loginSuccess.next(true);
        } else {
          this.loginSuccess.next(false);
          this.clearLocalStorage();
        }
      },
      (error) => {
        this.loginSuccess.next(false);
        this.clearLocalStorage();
      }
    );
  }

  logout() {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      this.loginSuccess.next(false);
      this.clearLocalStorage();
      return;
    }
    const dataUrl = getRootUrl() + '/remove_token';
    // token is an access token, not a refresh token.
    const body = {
      username: this.getCurrentUser(),
      token,
    };
    this.httpClient.post<Outcome>(dataUrl, body).subscribe(
      (data) => {
        console.log('Logged out : ', data);
      },
      (error) => {
        console.log('Logged out : ', error);
      }
    );
    localStorage.removeItem('last-activity');
    this.loginSuccess.next(false);
    this.clearLocalStorage();
  }

  clearLocalStorage() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(LOGIN);
    localStorage.removeItem(ROLE);
  }

  isAuthenticated() {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) {
      return false;
    }

    if (!this.isTokenStillValid(decodedToken)) {
      this.clearLocalStorage();
      return false;
    }

    return true;
  }

  getDecodedToken() {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return null;
    }
    return this.decodeToken(token);
  }

  isTokenStillValid(decodedToken) {
    const exp = decodedToken.exp;
    const now = new Date().getTime() / 1000;
    return now < exp;
  }

  isAdmin() {
    const token = this.getDecodedToken();
    return token && token.role + '' === 'Administrator';
  }

  isAdminOrInstructor() {
    if (this.isAdmin()) {
      return true;
    }
    return this.isInstructor();
  }

  isAdminOrInstructorOrAssistant() {
    if (this.isAdminOrInstructor()) {
      return true;
    }
    return this.isAssistant();
  }

  isMe(login: string) {
    return login === this.getCurrentUser();
  }

  isMeOrPrivileged(login: string) {
    return this.isAdminOrInstructorOrAssistant() || this.isMe(login);
  }

  isInstructor() {
    const token = this.getDecodedToken();
    return token && token.role + '' === 'Instructor';
  }

  isAssistant() {
    const token = this.getDecodedToken();
    return token && token.role + '' === 'Assistant';
  }

  isStudent() {
    const token = this.getDecodedToken();
    return token && token.role + '' === 'Student';
  }

  getCurrentUser() {
    const token = this.getDecodedToken();
    return token.sub;
  }

  private getToken(login: string, password: string): Observable<AuthenticationResponse> {
    const dataUrl = getRootUrl() + '/authenticate';
    const body = {
      login,
      password,
    };
    return this.httpClient.post<AuthenticationResponse>(dataUrl, body);
  }

  public isServerAvailable() {
    const dataUrl = getRootUrl() + '/';
    return this.httpClient.get(dataUrl);
  }

  private urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        // tslint:disable-next-line:no-string-throw
        throw 'Illegal base64url string!';
    }
    return decodeURIComponent(escape(atob(output)));
  }

  private decodeToken(token: string = '') {
    if (token === null || token === '') {
      return { upn: '' };
    }
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }

  public changePassword(studentId: number, password: string) {
    const dataUrl = getRootUrl() + '/students/' + studentId + '/password';
    return this.httpClient.put<Outcome>(dataUrl, { password });
  }

  public getUserCaption() {
    return localStorage.getItem(LOGIN) + ' : ' + localStorage.getItem(ROLE);
  }
}
