import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CallService } from '../services/call.service';
import { getRootUrl } from 'src/environments/environment';
import { ToastService } from '../services/toast.service';
import { NO_SERVER } from '../model/constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private rootUrl: string;
  constructor(private router: Router, public toastService: ToastService, private callService: CallService) {
    this.rootUrl = getRootUrl();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json',
        },
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    localStorage.setItem('last-activity', '' + new Date().getTime() / 1000);

    this.callService.addCall(this.cleanUrl(request.url));

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Uncomment this to see what's happening
          // console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('token.interceptor got an error of status ', error.status);
        console.log(error);
        if (error.status === 401) {
          if (!request.url.includes('remove_token')) {
            this.toastService.presentToastWarning('Sorry, your login attempt failed.');
          }
          localStorage.removeItem('token');
          this.router.navigate(['login']);
        }
        if (error.status === 0) {
          this.toastService.presentToastWarning(NO_SERVER);
          this.router.navigate(['login']);
        }
        return throwError(error);
      })
    );
  }

  cleanUrl(url: string) {
    let newUrl = url.replace(this.rootUrl, '');
    for (let i = 0; i < 10; i++) {
      newUrl = newUrl.split('' + i).join('?');
    }
    return newUrl;
  }
}
