import { Component, OnInit } from '@angular/core';
import { KungFuClassDetails } from 'src/app/model/kung-fu-class-details';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-class-picker-popover',
  templateUrl: './class-picker-popover.component.html',
  styleUrls: ['./class-picker-popover.component.scss'],
})
export class ClassPickerPopoverComponent implements OnInit {
  classes: KungFuClassDetails[];

  constructor(private router: Router, private popoverController: PopoverController) {
    this.classes = JSON.parse(localStorage.getItem('class-list')).classes;
  }

  ngOnInit() {}

  getTypeClass(kungFuClass) {
    if (!kungFuClass) {
      return 'adult';
    }
    if (!kungFuClass.type) {
      return 'adult';
    }
    return kungFuClass.type.replace(/ /g, '_').toLowerCase();
  }

  getType(kungFuClass) {
    if (!kungFuClass) {
      return 'A';
    }
    if (!kungFuClass.type) {
      return 'A';
    }
    return kungFuClass.type.substring(0, 1);
  }

  click(classId: number) {
    this.router.navigate(['classes', 'class', classId, 'students']);
    this.popoverController.dismiss();
  }
}
