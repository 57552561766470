import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'students',
    canActivate: [AuthGuard],
    loadChildren: () => import('./students/students.module').then((m) => m.StudentsPageModule),
  },
  {
    path: 'classes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./classes/classes.module').then((m) => m.ClassesPageModule),
  },
  {
    path: 'gradings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./gradings/gradings.module').then((m) => m.GradingsPageModule),
  },
  {
    path: 'schools',
    canActivate: [AuthGuard],
    loadChildren: () => import('./schools/schools.module').then((m) => m.SchoolsPageModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then((m) => m.RegisterPageModule),
  },
  {
    path: 'forgot',
    loadChildren: () => import('./forgot/forgot.module').then((m) => m.ForgotPageModule),
  },
  {
    path: 'system',
    canActivate: [AuthGuard],
    loadChildren: () => import('./system/system.module').then((m) => m.SystemPageModule),
  },
  {
    path: 'version-history',
    canActivate: [AuthGuard],
    loadChildren: () => import('./version-history/version-history.module').then((m) => m.VersionHistoryPageModule),
  },
  {
    path: 'audit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./audit/audit.module').then((m) => m.AuditPageModule),
  },
  {
    path: 'payments/:payment_id/details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./payment-details/payment-details.module').then((m) => m.PaymentDetailsPageModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m) => m.HelpPageModule),
  },
  {
    path: 'administration',
    loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationPageModule),
  },
  {
    path: 'administration/registrations',
    canActivate: [AuthGuard],
    loadChildren: () => import('./administration/registrations/registrations.module').then((m) => m.RegistrationsPageModule),
  },

  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
