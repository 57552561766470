import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { StudentService } from '../services/student.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private studentService: StudentService, private router: Router) {}

  canActivate(): boolean {
    const isAuthenticated = this.authenticationService.isAuthenticated();
    if (!isAuthenticated) {
      this.router.navigate(['login']);
      return true;
    }

    if (!this.authenticationService.isAdminOrInstructorOrAssistant()) {
      const currentUser = this.authenticationService.getCurrentUser();
      // this.studentService.getStudentDetailsByLogin(currentUser).subscribe((student) => {
      //   this.router.navigate(['students', 'student', student.id]);
      // });
    }

    return isAuthenticated;
  }
}
