import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StudentDetails } from '../model/student-details';
import { Student } from '../model/student';
import { getRootUrl } from 'src/environments/environment';
import { OutcomeWithStudent } from '../model/outcome-with-student';
import { StudentInClass } from '../model/student-in-class';
import { StudentClassAttendance } from '../model/student-class-attendance';
import { StudentSearchFilter } from '../model/student-search-filter';
import { Outcome } from '../model/outcome';
import { Audit } from '../model/audit';
import { ClassAttended } from '../model/class-attended';
import { StudentList } from '../model/student-list';
import { StudentRegistrations } from '../model/student-registrations';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  constructor(private httpClient: HttpClient) {}

  getStudents(filter: StudentSearchFilter): Observable<StudentList> {
    let dataUrl = getRootUrl() + '/students';
    if (filter) {
      const params = this.buildParams(filter);
      if (params !== '') {
        dataUrl = dataUrl + params;
      }
    }
    return this.httpClient.get<StudentList>(dataUrl);
  }

  buildParams(filter: StudentSearchFilter) {
    let params = '';
    if (filter.searchText) {
      params = params + this.addParam(params, 'search=', filter.searchText);
    }
    if (filter.studentType) {
      params = params + this.addParam(params, 'type=', filter.studentType);
    }
    if (filter.schoolId) {
      params = params + this.addParam(params, 'school_id=', filter.schoolId);
    }
    if (filter.gradeId) {
      params = params + this.addParam(params, 'grade_id=', filter.gradeId);
    }
    params = params + this.addParam(params, 'active_only=', !filter.includeInactive);

    return params;
  }

  addParam(existingParams, param, value) {
    if (existingParams === '') {
      return '?' + param + value;
    } else {
      return '&' + param + value;
    }
  }

  getActiveStudents(filter: StudentSearchFilter): Observable<StudentList> {
    let dataUrl = getRootUrl() + '/students';
    if (filter) {
      const params = this.buildParams(filter);
      if (params !== '') {
        dataUrl = dataUrl + params + '&active_only=true';
      } else {
        dataUrl = dataUrl + '?active_only=true';
      }
    } else {
      dataUrl = dataUrl + '?active_only=true';
    }
    return this.httpClient.get<StudentList>(dataUrl);
  }

  getStudent(studentId: number): Observable<Student> {
    const dataUrl = getRootUrl() + '/students/' + studentId;
    return this.httpClient.get<Student>(dataUrl);
  }

  getStudentHistory(studentId: number): Observable<Audit[]> {
    const dataUrl = getRootUrl() + '/students/' + studentId + '/history';
    return this.httpClient.get<Audit[]>(dataUrl);
  }

  getStudentDetailsByLogin(login: string): Observable<StudentDetails> {
    const dataUrl = getRootUrl() + '/student?login=' + login + '&details=true';
    return this.httpClient.get<StudentDetails>(dataUrl);
  }

  getClassAttendance(studentId: number): Observable<StudentClassAttendance[]> {
    const dataUrl = getRootUrl() + '/students/' + studentId + '/class_attendance';
    return this.httpClient.get<StudentClassAttendance[]>(dataUrl);
  }

  getClassesAttended(studentId: number): Observable<ClassAttended[]> {
    const dataUrl = getRootUrl() + '/students/' + studentId + '/classes_attended';
    return this.httpClient.get<ClassAttended[]>(dataUrl);
  }

  getStudentDetails(studentId: number): Observable<StudentDetails> {
    const dataUrl = getRootUrl() + '/students/' + studentId + '?details=true';
    return this.httpClient.get<StudentDetails>(dataUrl);
  }

  addStudent(student: Student): Observable<OutcomeWithStudent> {
    const dataUrl = getRootUrl() + '/students/';
    return this.httpClient.post<OutcomeWithStudent>(dataUrl, student);
  }

  registerStudent(student: Student): Observable<OutcomeWithStudent> {
    const dataUrl = getRootUrl() + '/register/';
    return this.httpClient.post<OutcomeWithStudent>(dataUrl, student);
  }

  updateStudent(student: Student): Observable<OutcomeWithStudent> {
    const dataUrl = getRootUrl() + '/students/' + student.id;
    return this.httpClient.put<OutcomeWithStudent>(dataUrl, student);
  }

  createPassword(studentId: number, password: string): Observable<OutcomeWithStudent> {
    const dataUrl = getRootUrl() + '/register/' + studentId + '/password';
    return this.httpClient.put<OutcomeWithStudent>(dataUrl, { password });
  }

  updatePassword(studentId: number, password: string): Observable<OutcomeWithStudent> {
    const dataUrl = getRootUrl() + '/students/' + studentId + '/password';
    return this.httpClient.put<OutcomeWithStudent>(dataUrl, { password });
  }

  getClassesForStudent(studentId: number): Observable<StudentInClass[]> {
    const dataUrl = getRootUrl() + '/students/' + studentId + '/classes';
    return this.httpClient.get<StudentInClass[]>(dataUrl);
  }

  checkLoginOK(login: string): Observable<Outcome> {
    const dataUrl = getRootUrl() + '/register/login/' + login;
    return this.httpClient.get<Outcome>(dataUrl);
  }

  checkNameOk(firstName: string, lastName: string): Observable<Outcome> {
    const dataUrl = getRootUrl() + '/register/check_name?first_name=' + firstName + '&last_name=' + lastName;
    return this.httpClient.get<Outcome>(dataUrl);
  }

  getRegistrations(): Observable<StudentRegistrations> {
    const dataUrl = getRootUrl() + '/students/registrations';
    return this.httpClient.get<StudentRegistrations>(dataUrl);
  }

  getRegistrationsForSchool(schoolId: number): Observable<StudentRegistrations> {
    const dataUrl = getRootUrl() + '/students/registrations?school_id=' + schoolId;
    return this.httpClient.get<StudentRegistrations>(dataUrl);
  }
}
