import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      color: 'dark',
      cssClass: 'toast-info',
      showCloseButton: true,
      closeButtonText: 'Ok',
    });
    toast.present();
  }

  async presentToastWarning(message: string) {
    const toast = await this.toastController.create({
      message,
      color: 'warning',
      cssClass: 'toast-warning',
      duration: 2000,
      showCloseButton: true,
      closeButtonText: 'Ok',
    });
    toast.present();
  }

  async presentToastError(message: string) {
    const toast = await this.toastController.create({
      message,
      color: 'danger',
      cssClass: 'toast-error',
      duration: 2000,
      showCloseButton: true,
      closeButtonText: 'Ok',
    });
    toast.present();
  }
}
