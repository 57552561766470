import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  private calls: {} = {};

  addCall(name: string) {
    if (this.calls[name]) {
      this.calls[name] = this.calls[name] + 1;
    } else {
      this.calls[name] = 1;
    }
  }

  getCalls(): any[] {
    const callList = [];
    Object.keys(this.calls).forEach(name => {
      const count = this.calls[name];
      callList.push({ name, count });
    });

    callList.sort((a, b) => (a.name > b.name ? 1 : -1));
    return callList;
  }
}
